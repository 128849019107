import React from "react";
import "./Loading.scss";

export default () => (
  <div className="loading--dots">
    <span />
    <span />
    <span />
  </div>
);
