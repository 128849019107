export default {
  locale: "no",

  Table: {
    locale: "no",
    description: "Beskrivelse",
    region: "Region",
    dark: "Slukket",
    seamap: "Sjøkart",
    owner: "Eier",
    categori: "Kategori",
    sjomerkenr: "Sjømerkenr",
    efsnum: "EFS nr",
    kategori: "Kategori",
    showAll: "Vis alle egenskaper",
    showLess: "Vis mindre egenskaper",
    zoomTo: "Zoom til posisjon",
    kat1: "Kategori 1: Avgjørende for sikker navigasjon",
    kat2: "Kategori 2: Viktig for sikker navigasjon",
    kat3: "Kategori 3: Mindre viktig for sikker navigasjon",
  },

  App: {
    locale: "no",
  },

  MapLayout: {
    locale: "no",
    kartlagsVelger: "Velg kartlag",
    zoomInTip: "Zoom inn",
    zoomOutTip: "Zoom ut",
    zoomToExtentTip: "Zoom til start",
  },
  Popup: {
    text: `<p>
Publisering av slukkede fyrlys på Kystinfo er ikke ment å være en erstatning for, 
eller et alternativ til ordinære navigasjonsvarsel.
</p>

<p>Publiseringen fritar ikke sjøfarende 
fra deres ansvar for å følge med på offisielle navigasjonsvarsel sendt over NAVTEX,
SafetyCast og SafetyNET i samsvar med nasjonale regler og bestemmelsene i SOLAS, 
samt sikkerhetsinformasjon gitt i Etterretninger for sjøfarende (Efs).
</p>`,
    closeButtonText: "Lukk",
    toolTip: "Vis infoboks",
  },

  NavBar: {
    index: "Hjem",
    tooltip: "Show in english",
  },
};
