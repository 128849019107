export default {
  locale: "en",

  Table: {
    locale: "en",
    description: "Description, name",
    region: "Region",
    dark: "Unlit",
    seamap: "Chart",
    owner: "Owner",
    categori: "Categori",
    sjomerkenr: "Light no",
    efsnum: "NM",
    kategori: "Categori",
    showAll: "Show all properties",
    showLess: "Show less properties",
    zoomTo: "Zoom to position",
    kat1: "Category 1: Vital navigational significance",
    kat2: "Category 2: Important navigational significance",
    kat3: "Category 3: Necessary navigational significance",
  },

  App: {
    locale: "en",
  },
  MapLayout: {
    locale: "en",
    kartlagsVelger: "Select maplayer",
    zoomToExtentTip: "Zoom to start",
    zoomInTip: "Zoom in",
    zoomOutTip: "Zoom out",
  },
  Popup: {
    text: "<p>The provision of information on unlit lights (Aids to Navigation) at Kystinfo is not intended to be a substitute for, or an alternative to the International Enhanced Group Call (EGC) Services or the International NAVTEX service.<p/><p> The provision of information does not relieve Masters/Captains of their responsibility to monitor MSI broadcasts in accordance with the provisions of SOLAS or safety information published in regular Notices to Mariners (NtM).</p>",
    closeButtonText: "Close",
    toolTip: "See infobox",
  },
  NavBar: {
    index: "Home",
    tooltip: "Vis på norsk",
  },
};
