import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { provideViewSize } from "react-adaptive-utils";
import Icon from "./Icon";
import "./Table.scss";
import Loading from "./loading/Loading";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.selectedRow = React.createRef();

    this.state = {
      expanded: undefined,
    };
  }
  static propTypes = {
    loading: PropTypes.bool,
    records: PropTypes.array,
    onRowSelected: PropTypes.func,
    selected: PropTypes.number,
    idProperty: PropTypes.string.isRequired,
    width: PropTypes.number,
    header: PropTypes.string,
    className: PropTypes.string,
    headerKey: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    const { selected, viewWidth, records, loading } = this.props;

    if (prevProps.selected !== selected && this.selectedRow.current) {
      if (viewWidth <= 768) {
        this.selectedRow.current.scrollIntoView(true);
      } else {
        this.selectedRow.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    !loading &&
      records.length &&
      this.selectedRow &&
      this.selectedRow.current &&
      this.selectedRow.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }

  onExpandRowClick = (r) => {
    const { idProperty } = this.props;
    const { expanded } = this.state;
    this.setState({
      expanded: expanded === r[idProperty] ? undefined : r[idProperty],
    });
  };

  render() {
    const {
      loading,
      records,
      t,
      selected,
      onRowSelected,
      onRowZoomToClick,
      idProperty,
      viewWidth,
      className,
      header,
      headerKey,
    } = this.props;
    const { expanded } = this.state;

    return (
      <div className={`table--root ${className}`}>
        <h3 className="table--flex-col">{header ? header : t(headerKey)}</h3>
        <section className="table--flex-grid">
          <header className="table--flex-row header">
            {viewWidth > 1200 && (
              <React.Fragment>
                <div className="table--flex-col search" />
                <div className="table--flex-col sjomerkenr">
                  {t("sjomerkenr")}
                </div>
                <div className="table--flex-col description">
                  {t("description")}
                </div>
                <div className="table--flex-col sjokartnr">{t("seamap")}</div>
                <div className="table--flex-col owner">{t("owner")}</div>
                <div className="table--flex-col dark">{t("dark")}</div>
                <div className="table--flex-col efsnum">{t("efsnum")}</div>
              </React.Fragment>
            )}
          </header>
          {loading && <Loading />}
          {!loading &&
            records.map((r) => (
              <div
                ref={selected === r[idProperty] && this.selectedRow}
                key={r[idProperty]}
                className={[
                  "table--flex-row",
                  selected === r[idProperty] ? "selected" : "",
                ].join(" ")}
                onClick={(d) => {
                  onRowSelected(r[idProperty]);
                }}
              >
                <div className="table--flex-col zoom">
                  <button
                    title={t("zoomTo")}
                    className="btn-link"
                    onClick={(e) => {
                      onRowZoomToClick(r);
                      e.stopPropagation();
                    }}
                  >
                    <Icon name="zoom" />
                  </button>
                </div>
                <div
                  data-header={t("sjomerkenr")}
                  className="table--flex-col sjomerkenr"
                >
                  {r.sjomerkenr}
                </div>
                <div
                  data-header={t("description")}
                  className="table--flex-col description"
                >
                  {r.beskrivelse.substr(r.beskrivelse.indexOf(",") + 1).trim()}
                </div>
                {viewWidth < 1200 && (
                  <div
                    data-header={t("kategori")}
                    className="table--flex-col kategori"
                  >
                    {r.kategori}
                  </div>
                )}
                {(viewWidth > 768 || r[idProperty] === expanded) && (
                  <React.Fragment>
                    <div
                      data-header={t("seamap")}
                      className="table--flex-col sjokartnr"
                    >
                      {r.kartnr}
                    </div>
                    <div
                      data-header={t("owner")}
                      className="table--flex-col owner"
                    >
                      {r.eier}
                    </div>
                    <div
                      data-header={t("dark")}
                      className="table--flex-col dark"
                    >
                      {r.slukket}
                    </div>
                    <div
                      data-header={t("efsnum")}
                      className="table--flex-col efsnum"
                    >
                      {r.efsnum}
                    </div>
                  </React.Fragment>
                )}
                <button
                  title={
                    r[idProperty] === expanded ? t("showLess") : t("showAll")
                  }
                  className="table--show-all btn-link"
                  onClick={(e) => {
                    this.onExpandRowClick(r);
                  }}
                >
                  {/* Vis mere */}
                  <Icon
                    name={
                      r[idProperty] === expanded ? "chevronup" : "chevrondown"
                    }
                  />
                </button>
              </div>
            ))}
        </section>
      </div>
    );
  }
}

export default translate("Table")(provideViewSize(Table));
