import React from "react";
import PropTypes from "prop-types";
import Title from "../components/Title";
import Loading from "../components/loading/Loading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pageActions } from "@avinet/react-adaptive-cms";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import config from "../config";
import "./PageView.scss";

const getPath = (location) => {
  var pathname = location.pathname;
  if (config.site === 1 && pathname.indexOf("/en") === 0) {
    pathname = pathname.slice(3);
  }
  return pathname.length > 1 ? pathname.slice(1) : "index";
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, pageActions), dispatch);
};

const mapStateToProps = (state) => ({
  page: state.page.page,
  pageLoading: state.page.pageLoading,
});

class PageView extends React.Component {
  constructor() {
    super();

    this.state = {
      currentPath: "",
    };
  }

  componentDidMount() {
    const { loadPage, location } = this.props;
    const path = getPath(location);
    this.setState({ currentPath: path });
    loadPage(path);
  }

  componentWillReceiveProps(nextProps) {
    const path = getPath(nextProps.location);
    if (path !== this.state.currentPath) {
      this.setState({ currentPath: path });
      nextProps.loadPage(path);
    }
  }

  render404() {
    const { t } = this.props;
    return (
      <div>
        <h3>{t("pageNotFound")}</h3>
        <p>
          {t("checkAddress")} <a href="/">{t("homePage")}</a>
        </p>
      </div>
    );
  }

  render() {
    const { page, pageLoading } = this.props;
    return (
      <div className="page-view--container">
        <Title
          title={
            (page.is404 ? "Page not found" : page.title || "Loading") +
            " - Kystverket"
          }
        />
        <div className="page-view--content">
          {pageLoading ? (
            <Loading />
          ) : page.is404 ? (
            this.render404()
          ) : (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: page.content }}
            />
          )}
        </div>
      </div>
    );
  }
}

PageView.propTypes = {
  location: PropTypes.object,
  loadPage: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("PageView")(PageView)));
