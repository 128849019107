import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";

import "./PageLayout.scss";

function PageLayout({ children, setLang, lang }) {
  return (
    <div className="page-layout--root artwork--container">
      <NavBar lang={lang} setLang={setLang} />
      <div className="page-layout--components">{children}</div>
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.any,
  setLang: PropTypes.func,
  lang: PropTypes.string,
};

export default PageLayout;
