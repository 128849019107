import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import createStore from "./redux/createStore";
import { injectReducer } from "./redux/reducers";
import App from "./App";
import config from "./config";
import { CmsConfig } from "@avinet/react-adaptive-cms";
import "./index.scss";

CmsConfig.init(config.adaptiveUrl, config.site);

const initState = window.__INITIAL_STATE__;
const store = createStore(initState);

export function addReducerToStore(key, reducer) {
  injectReducer(store, { key, reducer });
}

ReactDOM.render(<App store={store} />, document.getElementById("root"));
