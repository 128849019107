import React from "react";
import { TranslatorProvider, useTranslate } from "react-translate";
import PopupBox from "./components/PopupBox";
import Router from "./router";
import translations from "./translations";
import { Provider } from "react-redux";

import "./App.scss";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "no",
      showTipBox: true,
    };
  }

  getTranslations(lang) {
    return translations[lang];
  }

  onClose() {
    this.setState({ showTipBox: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lang && prevState.lang !== this.state.lang)
      this.setState({ showTipBox: true });
  }

  render() {
    const { store } = this.props;
    const { showTipBox } = this.state;
    return (
      <Provider store={store}>
        <TranslatorProvider
          translations={this.getTranslations(this.state.lang)}
        >
          <Router
            lang={this.state.lang}
            setLang={(lang) => this.setState({ lang })}
          />
          {showTipBox && <Popup onClose={() => this.onClose()} />}
        </TranslatorProvider>
      </Provider>
    );
  }
}

export default App;
const Popup = ({ onClose }) => {
  const t = useTranslate("Popup");
  return (
    <PopupBox onClose={onClose} closeBtnText={t("closeButtonText")}>
      <div dangerouslySetInnerHTML={{ __html: t("text") }}></div>
    </PopupBox>
  );
};
