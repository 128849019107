import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const icons = {
  zoom: (
    <svg viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  share: (
    <svg viewBox="0 0 32 32">
      <path d="M8 20c0 0 1.838-6 12-6v6l12-8-12-8v6c-8 0-12 4.99-12 10zM22 24h-18v-12h3.934c0.315-0.372 0.654-0.729 1.015-1.068 1.374-1.287 3.018-2.27 4.879-2.932h-13.827v20h26v-8.395l-4 2.667v1.728z" />
    </svg>
  ),
  envelope: (
    <svg viewBox="0 0 20 20">
      <path d="M18 2c1.105 0 2 0.895 2 2v0 12c0 1.105-0.895 2-2 2v0h-16c-1.105 0-2-0.895-2-2v0-12c0-1.1 0.9-2 2-2h16zM13.63 11.1l6.37 4.9v-2l-5.12-3.9 5.12-4.1v-2l-10 8-10-8v2l5.12 4.1-5.12 3.9v2l6.37-4.9 3.63 2.9 3.63-2.9z" />
    </svg>
  ),
  load: (
    <svg viewBox="0 0 32 32">
      <path d="M12 4c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM20.485 7.515c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM26 16c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.485 24.485c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM14 28c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM5.515 24.485c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM4.515 7.515c0 0 0 0 0 0 0-1.657 1.343-3 3-3s3 1.343 3 3c0 0 0 0 0 0 0 1.657-1.343 3-3 3s-3-1.343-3-3zM1.75 16c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25c0 1.243-1.007 2.25-2.25 2.25s-2.25-1.007-2.25-2.25z" />
    </svg>
  ),
  chevronRight: (
    <svg viewBox="0 0 16 16">
      <path d="M5.5 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />
    </svg>
  ),
  chevronLeft: (
    <svg viewBox="0 0 16 16">
      <path d="M10.5 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
    </svg>
  ),
  chevrondown: (
    <svg viewBox="0 0 24 24">
      <path d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z" />
    </svg>
  ),
  chevronup: (
    <svg viewBox="0 0 24 24">
      <path d="M18.707 14.293l-6-6c-0.391-0.391-1.024-0.391-1.414 0l-6 6c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
    </svg>
  ),
  map: (
    <svg viewBox="0 0 28 28">
      <title>map</title>
      <path d="M8 0c0.266 0 0.5 0.234 0.5 0.5v23c0 0.187-0.109 0.359-0.266 0.438l-7.5 4c-0.078 0.047-0.156 0.063-0.234 0.063-0.266 0-0.5-0.234-0.5-0.5v-23c0-0.187 0.109-0.359 0.266-0.438l7.5-4c0.078-0.047 0.156-0.063 0.234-0.063zM27.5 0c0.266 0 0.5 0.234 0.5 0.5v23c0 0.187-0.109 0.359-0.266 0.438l-7.5 4c-0.078 0.047-0.156 0.063-0.234 0.063-0.266 0-0.5-0.234-0.5-0.5v-23c0-0.187 0.109-0.359 0.266-0.438l7.5-4c0.078-0.047 0.156-0.063 0.234-0.063zM10 0c0.078 0 0.156 0.016 0.219 0.047l8 4c0.172 0.094 0.281 0.266 0.281 0.453v23c0 0.266-0.234 0.5-0.5 0.5-0.078 0-0.156-0.016-0.219-0.047l-8-4c-0.172-0.094-0.281-0.266-0.281-0.453v-23c0-0.266 0.234-0.5 0.5-0.5z" />
    </svg>
  ),
  pin: (
    <svg viewBox="0 0 16 16">
      <path d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 9 5 9s5-4 5-9c0-2.761-2.239-5-5-5zM8 8.063c-1.691 0-3.063-1.371-3.063-3.063s1.371-3.063 3.063-3.063 3.063 1.371 3.063 3.063-1.371 3.063-3.063 3.063zM6.063 5c0 1.070 0.867 1.938 1.938 1.938s1.938-0.867 1.938-1.938-0.867-1.938-1.938-1.938c-1.070 0-1.938 0.867-1.938 1.938zM12.285 10.9c-0.222 0.348-0.451 0.678-0.682 0.988 0.037 0.017 0.073 0.035 0.108 0.052 0.76 0.38 1.101 0.806 1.101 1.059s-0.34 0.679-1.101 1.059c-0.957 0.479-2.31 0.753-3.712 0.753s-2.754-0.275-3.712-0.753c-0.76-0.38-1.101-0.806-1.101-1.059s0.34-0.679 1.101-1.059c0.036-0.018 0.072-0.035 0.108-0.052-0.231-0.31-0.461-0.64-0.682-0.988-1.061 0.541-1.715 1.282-1.715 2.1 0 1.657 2.686 3 6 3s6-1.343 6-3c0-0.817-0.654-1.558-1.715-2.1z" />
    </svg>
  ),
  earth: (
    <svg viewBox="0 0 32 32">
      <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 30c-1.967 0-3.84-0.407-5.538-1.139l7.286-8.197c0.163-0.183 0.253-0.419 0.253-0.664v-3c0-0.552-0.448-1-1-1-3.531 0-7.256-3.671-7.293-3.707-0.188-0.188-0.442-0.293-0.707-0.293h-4c-0.552 0-1 0.448-1 1v6c0 0.379 0.214 0.725 0.553 0.894l3.447 1.724v5.871c-3.627-2.53-6-6.732-6-11.489 0-2.147 0.484-4.181 1.348-6h3.652c0.265 0 0.52-0.105 0.707-0.293l4-4c0.188-0.188 0.293-0.442 0.293-0.707v-2.419c1.268-0.377 2.61-0.581 4-0.581 2.2 0 4.281 0.508 6.134 1.412-0.13 0.109-0.256 0.224-0.376 0.345-1.133 1.133-1.757 2.64-1.757 4.243s0.624 3.109 1.757 4.243c1.139 1.139 2.663 1.758 4.239 1.758 0.099 0 0.198-0.002 0.297-0.007 0.432 1.619 1.211 5.833-0.263 11.635-0.014 0.055-0.022 0.109-0.026 0.163-2.541 2.596-6.084 4.208-10.004 4.208z" />
    </svg>
  ),
  info: (
    <svg viewBox="0 0 16 16">
      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z" />
      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z" />
      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z" />
    </svg>
  )
};

const Icon = props => {
  const { name } = props;
  return <span className="icon">{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
