import React from "react";
import PropTypes from "prop-types";
import PopupBox from "../components/PopupBox";
import SVGIcon from "../components/Icon";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import superagent from "superagent";
import {
  VectorSource,
  Select,
  provideMapState,
  olUtils,
} from "@avinet/react-openlayers";
import { Style, Icon } from "ol/style";
import { Title } from "@avinet/react-adaptive-cms";
import lighthouseSelected from "../static/ikon_fyrlykt_selected.svg";
import lighthouse from "../static/ikon_fyrlykt.svg";
import Table from "../components/Table";
import config from "../config";

import "./LighthousesView.scss";

const sortArray = (a, b) => {
  if (a.region < b.region) {
    return -1;
  }
  if (a.region > b.region) {
    return 1;
  }
  return 0;
};

class LighthousesView extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const {
      match: {
        params: { id },
      },
      location: { search },
    } = props;
    const sq = search.replace("?", "").split("&");

    this.state = {
      selected: parseInt(id) || undefined,
      zoom: sq.indexOf("zoom=1") > -1,
      showTipBox: false,
      tmpHideTipBox: false,
      loading: false,
      records: [],
      cat1: [],
      cat2: [],
      cat3: [],
    };

    this.onTipBoxVisibilityChange = this.onTipBoxVisibilityChange.bind(this);
  }

  readLighthouses = () => {
    this.setState({ loading: true });
    const { mapExtent } = this.props;
    const extGeom = olUtils.createPolygonFromExtent(
      olUtils.bufferExtent(mapExtent, 1.2)
    );
    const extFeat = olUtils.createFeatureFromGeometry(extGeom);
    const wktExtent = olUtils.getWktFromFeature(extFeat);
    const request = {
      theme_uuid: config.themes.lighthouses.uuid,
      columns: config.themes.lighthouses.cols,
      filter: {
        filterColumns: [
          {
            comparisonOperator: "ST_INTERSECTS",
            name: "geom",
            netType: "geometry",
            value: "SRID=" + config.mapProjCode + ";" + wktExtent,
            logicalOperator: "AND",
          },
        ],
      },
      start: 0,
      limit: 0,
    };
    superagent
      .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/ReadAny")
      .send({ request })
      .set("X-Adaptive-SRID", config.mapProjCode)
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .then((res) => res.body)
      .then((res) => {
        const cat1 = [];
        const cat2 = [];
        const cat3 = [];

        if (!res.d.records) {
          this.setState({ loading: false, cat1, cat2, cat3 });
          return;
        }

        res.d.records.forEach((r) => {
          const cat = (r.kategori && r.kategori.substring(0, 1)) || null;
          r.olFeature = olUtils.createFeatureFromWkt(r.geom_wkt, r);
          switch (cat) {
            case "1":
              cat1.push(r);
              break;
            case "2":
              cat2.push(r);
              break;
            default:
              cat3.push(r);
          }
        });

        cat1.sort(sortArray);
        cat2.sort(sortArray);
        cat3.sort(sortArray);

        this.setState({
          loading: false,
          cat1,
          cat2,
          cat3,
          records: res.d.records,
        });
      });
  };

  componentDidMount() {
    this.readLighthouses();
  }

  componentDidUpdate(prevProps, prevState) {
    const { fitViewAndZoom } = this.props;
    const { zoom, selected, records } = this.state;
    if (
      prevState.loading &&
      !this.state.loading &&
      prevState.records === 0 &&
      records.length > 0 &&
      zoom
    ) {
      var r = records.find((r) => r.id === selected);
      r && fitViewAndZoom(r.olFeature.getGeometry(), 13);
    }

    if (prevState.selected && prevState.selected !== selected) {
      const oldR = this.getRecordById(prevState.selected);
      oldR && oldR.olFeature.set("active", false);
    }

    records &&
      records.length &&
      selected &&
      this.getRecordById(this.state.selected) &&
      this.getRecordById(this.state.selected).olFeature.set("active", true);

    if (prevProps.mapExtent !== this.props.mapExtent) {
      setTimeout(() => this.readLighthouses(), 50);
    }
  }

  getRecordById = (id) => {
    return this.state.records.find((r) => r.id === id);
  };

  onRecordSelected = (rid) => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;

    if (id && parseInt(id) === rid) {
      history.push("/");
      this.setState({ selected: undefined });
    } else {
      history.push("/" + rid);
      this.setState({ selected: rid });
    }
  };

  onTipBoxVisibilityChange() {
    this.setState({ showTipBox: !this.state.showTipBox });
  }

  onRowZoomToClick = (r) => {
    const { history, fitViewAndZoom } = this.props;
    history.push(`/${r.id}?zoom=1`);

    this.setState({ selected: r.id });
    fitViewAndZoom(r.olFeature.getGeometry(), 10);
  };

  featureStyle = (f) => {
    const styles = [];
    const active = f.get("active");
    styles.push(
      new Style({
        image: new Icon({
          scale: 1.4,
          src: active ? lighthouseSelected : lighthouse,
          imgSize: [16, 16],
        }),
        zIndex: active ? 10 : 1,
      })
    );

    return styles;
  };

  onFeatureSelect = (features) => {
    features[0].set("active", true);
    this.onRecordSelected(features[0].get("id"));
    return false;
  };

  onFeatureDeselect = (features) => {
    features[0].set("active", false);
    return false;
  };

  render() {
    const { loading, selected, cat1, cat2, cat3, records, showTipBox } =
      this.state;
    const { t } = this.props;
    return (
      <div className="lighthouses-view--root">
        <Title title={"Slukkede fyrlykter - Kystverket"} />
        {showTipBox && (
          <PopupBox
            onClose={this.onTipBoxVisibilityChange}
            closeBtnText={t("closeButtonText")}
          >
            <div dangerouslySetInnerHTML={{ __html: t("text") }}></div>
          </PopupBox>
        )}
        <div className="buttons">
          <button
            title={t("toolTip")}
            className="article-view--tip-btn"
            onClick={() => this.setState({ showTipBox: true })}
          >
            <SVGIcon name="info" />
          </button>
        </div>
        {!loading && (
          <VectorSource
            features={records.map((r) => r.olFeature)}
            selectable={false}
            layerName="lighthouses"
            styleFn={() => this.featureStyle}
          >
            <Select
              onSelect={this.onFeatureSelect}
              onDeselect={this.onFeatureDeselect}
              hitTolerance={6}
            />
          </VectorSource>
        )}
        <Table
          loading={loading}
          records={cat1}
          selected={selected}
          onRowSelected={this.onRecordSelected}
          onRowZoomToClick={this.onRowZoomToClick}
          idProperty="id"
          className="cat1"
          headerKey={"kat1"}
        />

        <Table
          loading={loading}
          records={cat2}
          selected={selected}
          onRowSelected={this.onRecordSelected}
          onRowZoomToClick={this.onRowZoomToClick}
          idProperty="id"
          className="cat2"
          headerKey={"kat2"}
        />

        <Table
          loading={loading}
          records={cat3}
          selected={selected}
          onRowSelected={this.onRecordSelected}
          onRowZoomToClick={this.onRowZoomToClick}
          idProperty="id"
          className="cat3"
          headerKey={"kat3"}
        />
      </div>
    );
  }
}

export default translate("Popup")(withRouter(provideMapState(LighthousesView)));
