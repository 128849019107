import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MapLayout from "../layout/MapLayout";
import PageLayout from "../layout/PageLayout";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import LighthousesView from "../views/LighthousesView";

import PageView from "../views/PageView";

import config from "../config";

const browserHistory = createBrowserHistory({
  basename: config.basename,
});

const SetLang = ({ setLang }) => {
  useEffect(() => {
    setLang("en");
  }, [setLang]);

  return <Redirect to="/" />;
};

const AppRouter = ({ setLang, lang }) => {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Redirect from="/index" to="/" />
        <Redirect from="/no" to="/" />
        <Route path="/en">
          <SetLang setLang={setLang} />
        </Route>
        <Route path="/om">
          <PageLayout lang={lang} setLang={setLang}>
            <PageView />
          </PageLayout>
        </Route>
        <Route path="/om_en">
          <PageLayout lang={lang} setLang={setLang}>
            <PageView />
          </PageLayout>
        </Route>
        <Route path="/">
          <MapLayout lang={lang} setLang={setLang}>
            <Switch>
              <Route path="/:id" component={LighthousesView} />
              <Route exact path="/" component={LighthousesView} />
            </Switch>
          </MapLayout>
        </Route>
      </Switch>
    </Router>
  );
};

AppRouter.propTypes = {
  children: PropTypes.node,
  setLang: PropTypes.func,
  lang: PropTypes.string,
};

export default AppRouter;
