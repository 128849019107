export default {
  adaptiveUrl: "https://www.kystinfo.no/",
  site: 0,
  appName: "Kystverket - Slukkede fyrlykter",
  mapProjCode: "32633",
  mapProjection: "EPSG:32633",
  minZoom: 2,
  maxZoom: 19,
  initExtent: [
    -5206.686143482511, 6915575.004611748, 904681.3138565174, 8295301.004611748,
  ],
  themes: {
    lighthouses: {
      uuid: "9cf15ae5-16fe-4a4e-af61-47bb3d0cbba4",
      cols: [
        "id",
        "beskrivelse",
        "region",
        "kartnr",
        "status",
        "eier",
        "endretdato",
        "slukket",
        "efsnum",
        "prv_dvalue",
        "sjomerkenr",
        "geom",
        "kategori",
      ],
    },
  },
};

// http://kyvreports.kystverket.no/NavcoReport/slukte_fyrlys.aspx
