import React from "react";
import { provideMapState } from "@avinet/react-openlayers";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import Icon from "./Icon";
import "./ZoomToExtentButton.scss";

class ZoomToExtentButton extends React.Component {
  onClick = () => {
    const { extent, fitViewAndZoom, history } = this.props;
    history.push("/");
    fitViewAndZoom(extent);
  };

  render() {
    const { tooltip } = this.props;
    return (
      <button
        className="zoom-to-extent-btn"
        onClick={this.onClick}
        title={tooltip}
      >
        <Icon name={"earth"} />
      </button>
    );
  }
}

ZoomToExtentButton.propTypes = {
  extent: PropTypes.arrayOf(PropTypes.number).isRequired,
  fitViewAndZoom: PropTypes.func,
  tooltip: PropTypes.string,
};

export default provideMapState(withRouter(ZoomToExtentButton));
