import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import ZoomToExtentButton from "../components/ZoomToExtentButton";
import {
  Map,
  LayerSwitch,
  LayerGroup,
  WmsLayer,
  Zoom,
  XYZLayer,
} from "@avinet/react-openlayers";
import { translate } from "react-translate";
import Projection from "ol/proj/Projection";

import config from "../config";
import "./MapLayout.scss";

function MapLayout({ children, t, setLang, lang }) {
  return (
    <div className="map-layout--root">
      <NavBar lang={lang} setLang={setLang} />
      <MapWrapper>
        <ZoomToExtentButton
          extent={config.initExtent}
          tooltip={t("zoomToExtentTip")}
        />
        <div className="map-layout--components">{children}</div>
      </MapWrapper>
    </div>
  );
}

MapLayout.propTypes = {
  children: PropTypes.any,
  t: PropTypes.func.isRequired,
  setLang: PropTypes.func,
  lang: PropTypes.string,
};

export default translate("MapLayout")(MapLayout);

const MapWrapper = translate("MapLayout")((props) => {
  const { t, children } = props;
  return (
    <Map
      minZoom={config.minZoom}
      maxZoom={config.maxZoom}
      extent={config.initExtent}
      baatUrl={config.adaptiveUrl}
      hitTolerance={20}
      projDefs={[
        {
          epsg: "EPSG:32633",
          def: "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs",
        },
      ]}
      projection={
        new Projection({
          code: "EPSG:32633",
          extent: [-2500000, 3500000, 3045984, 9045984],
        })
      }
    >
      <Zoom zoomInTipText={t("zoomInTip")} zoomOutTipText={t("zoomOutTip")} />
      <LayerSwitch tooltip={t("kartlagsVelger")}>
        <LayerGroup id="basemap_group" enabledLayers={["sjokart"]} singleSelect>
          <WmsLayer
            id="sjokart"
            key="sjokart"
            name="Sjøkart"
            uri={["https://wms.geonorge.no/skwms1/wms.sjokartraster2"]}
            layerName="all"
            version="1.3.0"
            zIndex={-1}
          />

          <XYZLayer
            id="nib"
            key="nib"
            name="Norge i bilder"
            urls={[
              "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
              "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
              "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
            ]}
            zIndex={-1}
            useBaat
          />

          <XYZLayer
            id="graatone"
            key="topograatone"
            name="Gråtone"
            url="https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png"
            zIndex={-1}
          />

          <WmsLayer
            id="gebco"
            key="gebco"
            name="GEBCO"
            uri={["https://wms.geonorge.no/skwms1/wms.gebco_skyggerelieff2"]}
            layerName="gebco"
            version="1.3.0"
            zIndex={-1}
          />
        </LayerGroup>
      </LayerSwitch>
      {children}
    </Map>
  );
});
