import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Nav, Link, navActions } from "@avinet/react-adaptive-cms";
import logo_no_en from "../static/logo_Kystverket-english.svg";
import { translate } from "react-translate";
import "./NavBar.scss";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, navActions), dispatch);
};

const mapStateToProps = (state) => ({
  nav: state.nav.nav,
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
    };
  }

  componentDidMount() {
    const { loadNav } = this.props;
    loadNav();
  }

  get navItems() {
    const { nav, lang, t } = this.props;
    return nav.filter((n) => {
      if (n.uri === "index") {
        n.title = t("index");
        return n;
      }
      if (lang === "en") {
        return n.uri.indexOf("_en") > -1;
      } else {
        return n.uri.indexOf("_en") === -1;
      }
    });
  }

  render() {
    const { navOpen } = this.state;
    const { setLang } = this.props;
    const { t } = this.props;
    return (
      <div className="nav-bar--container">
        <Link to="/" className="nav-bar--header-image">
          <img src={logo_no_en} alt="Kystverket" />
        </Link>
        <div
          className={[
            "nav-bar--menu",
            navOpen ? " nav-bar--menu-active" : "",
          ].join(" ")}
        >
          <input
            type="checkbox"
            className="nav-bar--hamburger"
            checked={navOpen}
            onChange={() => {}}
            onClick={() =>
              this.setState({ navOpen: !navOpen, profileOpen: false })
            }
          />
          <span />
          <span />
          <span />
          <Nav
            nav={this.navItems}
            onNavClick={() => {
              this.setState({
                navOpen: false,
                profileOpen: false,
              });
            }}
          />
        </div>
        <div className="nav-bar--langbtns">
          {this.props.lang === "no" && (
            <button title={t("tooltip")} onClick={() => setLang("en")}>
              ENG
            </button>
          )}
          {this.props.lang === "en" && (
            <button title={t("tooltip")} onClick={() => setLang("no")}>
              NOR
            </button>
          )}
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  nav: PropTypes.array,
  loadNav: PropTypes.func,
  fullscreen: PropTypes.bool,
  fullscreenToggle: PropTypes.func,
  setLang: PropTypes.func,
  lang: PropTypes.string,
};

export default translate("NavBar")(
  connect(mapStateToProps, mapDispatchToProps)(NavBar)
);
